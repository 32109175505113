<template>
  <div class="table-responsive"><br>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
      <div class="card card-stats bg-success text-dark">
        <div class="card-header lead">
          <center>
            <strong class="lead">Activated</strong><br>
            <p class="text-sm">{{ all_customers[1] }}</p>
          </center>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
      <div class="card card-stats bg-danger text-dark">
        <div class="card-header lead">
          <center>  
            <strong class="lead">Pending</strong><br>
            <p class="text-sm">{{ all_customers[2] }}</p>
          </center>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
      <div class="card card-stats bg-danger text-dark">
        <div class="card-header lead">
          <center>  
            <strong class="lead">Pending Provisioning</strong><br>
            <p class="text-sm">{{ all_customers[4] }}</p>
          </center>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
      <div class="card card-stats bg-success text-dark">
        <div class="card-header lead">
          <center>  
            <strong class="lead">Provisioned</strong><br>
            <p class="text-sm">{{ all_customers[3] }}</p>
          </center>
        </div>
      </div>
    </div>
    </div>
    <h3>Ngmkt Customers</h3>
    <table id="table" class="table table-striped">
      <thead>
        <tr>
          <th></th>
          <th>Business Name</th>
          <th>Phone 1</th>
          <th>Phone 2</th>
          <th>Email Address</th>
          <th>Website Name</th>
          <th>Provisioning Status</th>
          <th>Subscription Status</th>
          <th>Activation Status</th>
          <th>Expiration Date</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody :key="tableKey">
        <tr v-for="(customer, index) in all_customers[0]" :key="customer.id">
          <td>{{index+1}}</td>
          <td>{{customer.name}}</td>
          <td>{{customer.phone_one}}</td>
          <td>{{customer.phone_two}}</td>
          <td>{{customer.email}}</td>
          <td>{{customer.website}}</td>
          <td v-if="customer.provisioning_status == 'true'" class="bg-success">Provisioned</td>
          <td v-else class="bg-danger">Pending provisioning</td>
          <td :class="customer.status == 'inactive' ? 'bg-danger text-dark' : 'bg-success'">{{ customer.status }}</td>
          <td :class="customer.platform_status == 'pending' ? 'bg-danger text-dark' : 'bg-success'">{{ customer.platform_status }}</td>
          <td>{{checkExpirationStatus(customer.expiry_date)}}</td>

          <td>
            <p-button class="mr-2" title="details" type="info" size="sm" icon @click.prevent="updateCustomer(customer)">
              <i class="fa fa-wrench"></i><span class="spinner-border" v-if="loading"></span>
            </p-button>
            <p-button class="mr-2" title="details" type="success" size="sm" icon @click.prevent="provisionCustomer(customer)">
              <i class="fa fa-check"></i><span class="spinner-border" v-if="loading"></span>
            </p-button>
          </td>
        </tr>
      </tbody>
    </table>


  </div>
</template>
<script>
  import { Modal } from '@/components/UIComponents'
  import axios from 'axios'
  import Swal from 'sweetalert2'
  import helpers from '@/javascript/helpers'

  export default{
     components: {
      Modal
    },
    data() {
      return {
        updateMode:false,
        all_customers:null,
        filter:1,
        tableKey:0,
        customer:null,
        fund:{
          amount:null,
          platform: 'offline'
        },
        modals: {
          classic: false,
          notice: false,
          mini: false
        },
        loading:false
      }
    },
    methods: {
       checkExpirationStatus(expirationTimestamp) {
    const now = Math.floor(Date.now() / 1000); // Current time in seconds (UNIX timestamp)
    const secondsInADay = 86400; // 24 * 60 * 60

    const diffInSeconds = expirationTimestamp - now;
    const diffInDays = Math.abs(Math.floor(diffInSeconds / secondsInADay)); // Absolute number of days

    if (diffInSeconds < 0) {
        return `Expired for ${diffInDays} day(s)`;
    } else if (diffInSeconds <= secondsInADay) {
        return "Today";
    } else {
        return `${diffInDays} day(s) to expiration`;
    }
},

getCustomers(){
          this.loading = true
          
          axios.get('https://api.ngmkt.site/api/customer').then((result) => {
            this.all_customers = result.data.data
            this.tableKey++
            this.datatable()
          })
          this.loading = false
      },
     
      provisionCustomer(customer){
        this.loading = true
        let paylod = {
              status: 'activated'
            }
          axios.put('https://api.ngmkt.site/api/admin/platform-status/'+customer.id, paylod).then((result) => {            
            this.tableKey++
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: result.data.message,
              customClass: 'Swal-wide',
              showConfirmButton: true,
              timer: 5000
            })
            this.getCustomers()
          })
          this.loading = false
      },
     

      datatable(){
        $(function() {
          $('#table').DataTable({
            dom: 'Bfrtip',
          buttons: ['excel', 'pdf', 'print'],
            "bDestroy": true,
                pageLength: 5,
                lengthMenu: [[5,10,20], [5, 10, 20]],
            });
        });
      },

    },
    created(){
      this.getCustomers()
    }

  }
</script>
