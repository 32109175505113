<template>
  <div class="table-responsive"><br>
    <div class="row">
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
      <div class="card card-stats bg-info">
        <div class="card-header lead">
          <center>
            <strong class="lead">Engaged</strong><br>
            <p class="text-sm">{{ all_enquiries['engaged']?.length }}</p>
          </center>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
      <div class="card card-stats bg-danger text-light">
        <div class="card-header lead">
          <center>  
            <strong class="lead">Pending</strong><br>
            <p class="text-sm">{{ all_enquiries['pending']?.length }}</p>
          </center>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
      <div class="card card-stats bg-success text-dark">
        <div class="card-header lead">
          <center>  
            <strong class="lead">Interested</strong><br>
            <p class="text-sm">{{ all_enquiries['interested_enquiries']?.length }}</p>
          </center>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-6 col-md-4 col-lg-3 mb-4">
      <div class="card card-stats bg-warning">
        <div class="card-header lead">
          <center>  
            <strong class="lead">Not Interested</strong><br>
            <p class="text-sm">{{ all_enquiries['uninterested_enquiries']?.length }}</p>
          </center>
        </div>
      </div>
    </div>
    </div>
    <h3>Enquiries</h3>

    <table id="table" class="table table-striped">
      <thead>
        <tr>
          <th></th>
          <th>Interest</th>
          <th>Name</th>
          <th>Phone 2</th>
          <th>Email Address</th>
          <th>Message</th>
          <th>Engagement Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody :key="tableKey">
        <tr v-for="(enquiry, index) in all_enquiries['all_enquiries']" :key="enquiry.id">
          <td>{{index+1}}</td>
          <td>{{enquiry.interest}}</td>
          <td>{{enquiry.name}}</td>
          <td>{{enquiry.number}}</td>
          <td>{{enquiry.email}}</td>
          <td>{{enquiry.message}}</td>
          <td :class="enquiry.status == 'pending_engagement' ? 'bg-danger' : 'bg-success'">{{ enquiry.status }}</td>
          <td>
            <p-button class="mr-2" title="details" type="success" size="sm" icon @click.prevent="engageCustomer(enquiry)">
              <i class="fa fa-check"></i> <span class="spinner-border" v-if="loading"></span>
            </p-button>
          </td>
        </tr>
      </tbody>
    </table>


  </div>
</template>
<script>
  import { Modal } from '@/components/UIComponents'
  import axios from 'axios'
  import Swal from 'sweetalert2'

  export default{
     components: {
      Modal
    },
    data() {
      return {
        updateMode:false,
        all_enquiries:null,
        filter:1,
        loading: false,
        tableKey:0,
        customer:null,
        fund:{
          amount:null,
          platform: 'offline'
        },
        modals: {
          classic: false,
          notice: false,
          mini: false
        },
      }
    },
    methods: {
      getEnquiries(){
          this.loading = true
          axios.get('https://api.ngmkt.site/api/enquiry/all').then((result) => {
            this.all_enquiries = result.data.data
            this.tableKey++
            this.datatable()
          })
          this.loading = false
      },
     
      engageCustomer(enquiry){
        this.loading = true
        let paylod = {
              interest: enquiry.interest,
              name: enquiry.name,
              number: enquiry.number,
              email: enquiry.email,
              message: enquiry.message,
              status: 'engaged'
            }
          axios.put('https://api.ngmkt.site/api/enquiry/update/'+enquiry.id, paylod).then((result) => {            
            this.tableKey++
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: result.data.message,
              customClass: 'Swal-wide',
              showConfirmButton: true,
              timer: 5000
            })
            this.getEnquiries()
          })
          this.loading = false
      },
     

      datatable(){
        $(function() {
          $('#table').DataTable({
            dom: 'Bfrtip',
          buttons: ['excel', 'pdf', 'print'],
            "bDestroy": true,
                pageLength: 5,
                lengthMenu: [[5,10,20], [5, 10, 20]],
            });
        });
      },

    },
    created(){
      this.getEnquiries()
    }

  }
</script>
